import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent {
  title = 'my-first-app';

  constructor(private router: Router,
              private _route: ActivatedRoute,
              ) { }

  routeToAboutUs() {
    this.router.navigate(['/aboutus']);
  }

  routeToHome() {
    this.router.navigate(['/']);
  }

}
