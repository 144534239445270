<nav class="navbar navbar-expand-lg navbar-dark navbar-custom fixed-top">
      <div class="container px-5">
        <a (click)="routeToHome()">
        <img src="../../../assets/IslandVibe.png" class="logo"> </a>
          <div class="collapse navbar-collapse show" style="box-shadow: none !important;"  id="navbarResponsive">
              <ul class="navbar-nav ms-auto">
                    <li class="nav-item"><a class="nav-link" (click)="routeToAboutUs()">About us</a></li>
              </ul>
          </div>
      </div>
</nav>

<router-outlet></router-outlet>

<footer class="py-5 bg-black">
    <div class="container px-5"><p class="m-0 text-center text-white small">Island Vibe Records 2024 | islandvibe.global@gmail.com </p></div>
</footer>
