import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-aboutus',
  templateUrl: './aboutus.component.html',
  styleUrls: ['./aboutus.component.css']
})

export class AboutUsComponent implements OnInit {

  constructor(private router: Router,
              private _route: ActivatedRoute
              ) { }

  ngOnInit(): void {
  }


  routeToHome() {
    this.router.navigate(['/']);
  }

}
